import Tooltip from "bootstrap/js/dist/tooltip";

(($) => {
  "use strict";

  $(() => {
    $("body.edit.show-edit-tooltips [id$=-help]").each((_, el) => {
      $(el)
        .prevAll(":not([type=hidden]):first")
        .attr({ "data-bs-toggle": "tooltip", title: el.textContent.trim() })
        .attr({ "data-bs-placement": "left" });
    });

    $("[data-bs-toggle=tooltip]").each((_, el) => new Tooltip(el));
  });
})(jQuery);
